import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import {
  ArrowLink,
  BlockRenderer,
  Grid,
  Margin,
  PageWrapper,
} from "@components/atoms"
import {
  GridItem,
  GridItemScrollStrip,
  Tags,
  NewsItem,
} from "@components/molecules"
import {
  Title,
  Description,
  ProjectTitle,
} from "@components/molecules/GridItem"
import { TransitionMask } from "@components/organisms"
import { PageProps } from "@types"
import { palette } from "@theme"
import { useDisplayMode, DisplayMode } from "@utils"

const IndexPage = ({
  data: {
    sanityPage: { gridItems, extraHomeFields },
  },
}: PageProps) => {
  const { displayMode } = useDisplayMode()
  if (!extraHomeFields) return <></>

  const { _rawIntroduction, ctaLink, ctaCopy } = extraHomeFields

  return (
    <PageWrapper>
      <Margin>
        <IntroWrapper>
          <Introduction>
            <BlockWrapper>
              <BlockRenderer>{_rawIntroduction}</BlockRenderer>
            </BlockWrapper>
            <LogoMarker id="logo_marker" />
            <ArrowLink to={`/` + ctaLink.slug.current}>{ctaCopy}</ArrowLink>
          </Introduction>
        </IntroWrapper>
      </Margin>

      <Margin>
        <StyledGrid wideMobileRows>
          {gridItems
            .filter(p => !!p)
            .map(item => (
              <GridItem key={item._key} {...item}>
                <Title>
                  <StyledProjectTitle
                    {...{ displayMode }}
                    className="linkHighlight"
                  >
                    {item.post?.title}
                  </StyledProjectTitle>
                  <StyledTags tags={item.post?.tags} />
                </Title>
                <Description {...{ displayMode }}>
                  {item.post?.description}
                </Description>
              </GridItem>
            ))}
        </StyledGrid>
      </Margin>

      {/*
        news && news.length > 0 && (
        <Margin black>
          <Section title="Lately">
            <StyledItemStrip stackMobile>
              {news.map((item, i) => (
                <NewsItem {...item} key={i} />
              ))}
            </StyledItemStrip>
          </Section>
        </Margin>
      )
    */}

      <TransitionMask />
    </PageWrapper>
  )
}

const LogoMarker = styled.div`
  @media only screen and (max-width: 743px) {
    position: absolute;
    top: -20px;
  }
`

const BlockWrapper = styled.div``

const StyledItemStrip = styled(props => <GridItemScrollStrip {...props} />)`
  grid-row-gap: 0px !important;
  @media only screen and (min-width: 744px) {
    grid-row-gap: 0px;
  }
`

const IntroWrapper = styled(props => <Grid {...props} />)`
  padding: 90px 0 0 0;
  @media only screen and (min-width: 744px) {
    padding: 0;
  }
`

const Introduction = styled.div`
  position: relative;
  grid-column-end: span 6;
  @media only screen and (min-width: 744px) {
    grid-column-start: 7;
  }
  > p:first-of-type {
    position: relative;
  }
`

const StyledGrid = styled(props => <Grid {...props} />)`
  @media only screen and (min-width: 744px) {
    padding: 45px 0 90px 0;
  }
  @media only screen and (min-width: 1024px) {
    padding: 60px 0 120px 0;
  }
`

export const StyledTags = styled(props => <Tags {...props} />)`
  @media only screen and (max-width: 1023px) {
    display: none;
  }
`

const StyledProjectTitle = styled(props => <ProjectTitle {...props} />)<{
  displayMode: DisplayMode
}>`
  color: ${props =>
    props.displayMode === "light" ? palette.black : palette.white};
`

export const query = graphql`
  query HomePageQuery {
    sanityPage(slug: { current: { eq: "home" } }) {
      ...pageFields
      extraHomeFields {
        _rawIntroduction
        ctaLink {
          ... on SanityPage {
            slug {
              current
            }
          }
          ... on SanityPost {
            slug {
              current
            }
          }
        }
        ctaCopy
      }
    }
  }
`

export default IndexPage

/* news query under extraHomeFields
        news {
  title
  link
  date
  _rawCaption
  _type
  newsType
  gridItemTypeSwitch {
    gridItemType

    gridItemCaption {
      _rawCaption
      anchor
    }

    gridItemImage {
      image {
        asset {
          url
          metadata {
            dimensions {
              width
              height
              aspectRatio
            }
          }
        }
      }
      mobileImage {
        asset {
          url
          metadata {
            dimensions {
              width
              height
              aspectRatio
            }
          }
        }
      }
      optionalImageProperties {
        _rawCaption
      }
    }

    gridItemVideo {
      video {
        asset {
          url
        }
      }
      mobileVideo {
        asset {
          url
        }
      }
    }
  }
}
*/
